.cloud-wcontainer {
  background: var(--widgets-container-background, #FFFFFF);
  display: flex;
  flex-direction: column;
  border-radius: var(--widgets-container-corner-radius, 4px);
  padding: var(--widgets-container-padding, 20px);
  box-shadow: var(--widgets-container-shadow, none); }
  .cloud-wcontainer.cloud-wcontainer-mobile {
    padding: var(--widgets-container-mobile-padding, 12px); }
    .cloud-wcontainer.cloud-wcontainer-mobile .cloud-wcontainer-title {
      padding: var(--widgets-container-mobile-padding, 12px); }
  .cloud-wcontainer .cloud-wcontainer-title {
    padding-bottom: var(--widgets-container-padding, 20px);
    font-size: var(--widgets-font-size-3, 16px);
    line-height: 1;
    color: var(--widgets-container-title, #262626); }
    .cloud-wcontainer .cloud-wcontainer-title.cloud-wcontainer-title-border {
      position: relative;
      padding-left: 0.75em; }
      .cloud-wcontainer .cloud-wcontainer-title.cloud-wcontainer-title-border:before {
        content: "";
        position: absolute;
        left: 0;
        width: 0.25em;
        height: 1em;
        background: var(--widgets-color-blue, #3F90FF);
        border-radius: 0.125em; }
    .cloud-wcontainer .cloud-wcontainer-title > .cloud-wcontainer-operation {
      float: right;
      font-size: var(--widgets-font-size-1, 12px); }
      .cloud-wcontainer .cloud-wcontainer-title > .cloud-wcontainer-operation a {
        color: var(--widgets-color-text-2, #595959); }
        .cloud-wcontainer .cloud-wcontainer-title > .cloud-wcontainer-operation a:hover {
          color: var(--widgets-color-text-1, #262626); }
  .cloud-wcontainer .cloud-wcontainer-main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-cross > .cloud-wcontainer-multi-row-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
      .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-cross > .cloud-wcontainer-multi-row-container .cloud-wcontainer-row {
        flex: 1;
        border-bottom: 1px solid var(--widgets-container-split-line, #E5E5E5); }
        .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-cross > .cloud-wcontainer-multi-row-container .cloud-wcontainer-row:last-child {
          border-bottom: none; }
        .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-cross > .cloud-wcontainer-multi-row-container .cloud-wcontainer-row .cloud-wcontainer-col {
          margin-right: var(--s1, 4px);
          margin-left: var(--s1, 4px);
          padding-left: var(--s3, 12px);
          border-left: 1px solid var(--widgets-container-split-line, #E5E5E5); }
          .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-cross > .cloud-wcontainer-multi-row-container .cloud-wcontainer-row .cloud-wcontainer-col:first-child {
            border-left: none;
            margin-left: 0;
            padding-left: 0; }
    .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-main-one-chart {
      overflow: hidden; }
    .cloud-wcontainer .cloud-wcontainer-main > .cloud-wcontainer-row {
      padding: 0; }
      .cloud-wcontainer .cloud-wcontainer-main > .cloud-wcontainer-row > .cloud-wcontainer-col {
        padding: 0; }
    .cloud-wcontainer .cloud-wcontainer-main.center {
      text-align: center; }
    .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-main-critical-error {
      align-items: center;
      overflow: auto;
      color: var(--widgets-color-red, #E62412); }
      .cloud-wcontainer .cloud-wcontainer-main.cloud-wcontainer-main-critical-error pre {
        margin: 0;
        padding: 0;
        font-size: 1em; }
  .cloud-wcontainer .cloud-wcontainer-divider {
    width: 1px;
    height: 30px;
    background: var(--widgets-container-split-line, #E5E5E5); }
  .cloud-wcontainer .cloud-wcontainer-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    .cloud-wcontainer .cloud-wcontainer-row *,
    .cloud-wcontainer .cloud-wcontainer-row *:before,
    .cloud-wcontainer .cloud-wcontainer-row *:after {
      box-sizing: border-box; }
    @media (min-width: 344px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 8px; } }
    @media (min-width: 504px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 8px; } }
    @media (min-width: 752px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 12px; } }
    @media (min-width: 1022px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 12px; } }
    @media (min-width: 1232px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 12px; } }
    @media (min-width: 1532px) {
      .cloud-wcontainer .cloud-wcontainer-row {
        padding: 0 12px; } }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-no-padding {
      padding: 0; }
      .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-no-padding > .cloud-wcontainer-col {
        padding: 0; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-across {
      padding: 0; }
      .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-across > :first-child {
        padding-left: 0; }
      .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-across > :last-child {
        padding-right: 0; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-justify-start {
      justify-content: flex-start; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-justify-end {
      justify-content: flex-end; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-justify-center {
      justify-content: center; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-justify-space-between {
      justify-content: space-between; }
    .cloud-wcontainer .cloud-wcontainer-row.cloud-wcontainer-row-justify-space-around {
      justify-content: space-around; }
  .cloud-wcontainer .cloud-wcontainer-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-block;
    padding: 0 4px;
    width: auto; }
    .cloud-wcontainer .cloud-wcontainer-col > .cloud-wcontainer-row {
      padding: 0; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-1 {
    max-width: 4.16667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 4.16667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 4.16667%;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-2 {
    max-width: 8.33333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-3 {
    max-width: 12.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 12.5%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-4 {
    max-width: 16.66667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-5 {
    max-width: 20.83333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20.83333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 20.83333%;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-6 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-7 {
    max-width: 29.16667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 29.16667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 29.16667%;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-8 {
    max-width: 33.33333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-9 {
    max-width: 37.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 37.5%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-10 {
    max-width: 41.66667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-11 {
    max-width: 45.83333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45.83333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 45.83333%;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-12 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-13 {
    max-width: 54.16667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 54.16667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 54.16667%;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-14 {
    max-width: 58.33333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-15 {
    max-width: 62.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 62.5%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-16 {
    max-width: 66.66667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-17 {
    max-width: 70.83333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70.83333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 70.83333%;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-18 {
    max-width: 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-19 {
    max-width: 79.16667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 79.16667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 79.16667%;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-20 {
    max-width: 83.33333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-21 {
    max-width: 87.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 87.5%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-22 {
    max-width: 91.66667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-23 {
    max-width: 95.83333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 95.83333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 95.83333%;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-24 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 20px;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    min-width: 20px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 40px;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    min-width: 40px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 60px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 60px;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    min-width: 60px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 80px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 80px;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    min-width: 80px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100px;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    min-width: 100px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 120px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 120px;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    min-width: 120px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 140px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 140px;
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    min-width: 140px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 160px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 160px;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px;
    min-width: 160px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 180px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 180px;
    -ms-flex: 0 0 180px;
    flex: 0 0 180px;
    min-width: 180px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 200px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 200px;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    min-width: 200px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 220px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 220px;
    -ms-flex: 0 0 220px;
    flex: 0 0 220px;
    min-width: 220px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 240px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 240px;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    min-width: 240px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-13 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 260px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 260px;
    -ms-flex: 0 0 260px;
    flex: 0 0 260px;
    min-width: 260px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-14 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 280px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 280px;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    min-width: 280px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-15 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 300px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 300px;
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    min-width: 300px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-16 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 320px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 320px;
    -ms-flex: 0 0 320px;
    flex: 0 0 320px;
    min-width: 320px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-17 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 340px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 340px;
    -ms-flex: 0 0 340px;
    flex: 0 0 340px;
    min-width: 340px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-18 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 360px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 360px;
    -ms-flex: 0 0 360px;
    flex: 0 0 360px;
    min-width: 360px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-19 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 380px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 380px;
    -ms-flex: 0 0 380px;
    flex: 0 0 380px;
    min-width: 380px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-20 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 400px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 400px;
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    min-width: 400px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-21 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 420px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 420px;
    -ms-flex: 0 0 420px;
    flex: 0 0 420px;
    min-width: 420px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-22 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 440px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 440px;
    -ms-flex: 0 0 440px;
    flex: 0 0 440px;
    min-width: 440px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-23 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 460px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 460px;
    -ms-flex: 0 0 460px;
    flex: 0 0 460px;
    min-width: 460px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-24 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 480px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 480px;
    -ms-flex: 0 0 480px;
    flex: 0 0 480px;
    min-width: 480px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-25 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 500px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 500px;
    -ms-flex: 0 0 500px;
    flex: 0 0 500px;
    min-width: 500px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-26 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 520px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 520px;
    -ms-flex: 0 0 520px;
    flex: 0 0 520px;
    min-width: 520px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-27 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 540px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 540px;
    -ms-flex: 0 0 540px;
    flex: 0 0 540px;
    min-width: 540px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-28 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 560px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 560px;
    -ms-flex: 0 0 560px;
    flex: 0 0 560px;
    min-width: 560px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-29 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 580px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 580px;
    -ms-flex: 0 0 580px;
    flex: 0 0 580px;
    min-width: 580px; }
  .cloud-wcontainer .cloud-wcontainer-row .cloud-wcontainer-col-fixed-30 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 600px;
    -moz-box-flex: 0;
    -moz-flex: 0 0 600px;
    -ms-flex: 0 0 600px;
    flex: 0 0 600px;
    min-width: 600px; }
  .cloud-wcontainer .cloud-wcontainer-row-align-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .cloud-wcontainer .cloud-wcontainer-row-align-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .cloud-wcontainer .cloud-wcontainer-row-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .cloud-wcontainer .cloud-wcontainer-row-align-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    align-items: baseline; }
  .cloud-wcontainer .cloud-wcontainer-row-align-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch; }
    .cloud-wcontainer .cloud-wcontainer-row-align-stretch .cloud-wcontainer-col {
      display: flex; }
